document.addEventListener('DOMContentLoaded', function () {
  const scrollBox = document.querySelector('.explore_blocks_wrapper');

  scrollBox.addEventListener('wheel', function (event) {
    const isAtStart = scrollBox.scrollLeft === 0;
    const isAtEnd =
      scrollBox.scrollWidth - scrollBox.offsetWidth === scrollBox.scrollLeft;

    if (!isAtStart && !isAtEnd) {
      event.preventDefault();
      scrollBox.scrollLeft += event.deltaY * 1.5;
    } else if (isAtEnd && event.deltaY > 0) {
    } else if (isAtStart && event.deltaY < 0) {
    } else {
      event.preventDefault();
      scrollBox.scrollLeft += event.deltaY * 1.5;
    }
  });
});
